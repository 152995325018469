<template>
  <!-- 消息管理 -->
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <div class="info-card tab-readNum top-10">
            <el-badge
              :value="readnum"
              :max="99"
              class="item readnum"
              v-if="readnum > 0"
            ></el-badge>
            <el-badge
              :value="unreadnum"
              :max="99"
              class="item unreadnum"
              v-if="unreadnum > 0"
            ></el-badge>
            <el-tabs v-model="activeName" @tab-click="changeMsgState">
              <el-tab-pane label="未读消息" name="first"> </el-tab-pane>
              <el-tab-pane label="已读消息" name="second"> </el-tab-pane>
            </el-tabs>
            <el-table :data="unreadTable" style="width: 100%">
              <el-table-column prop="id" label="序号" width="120">
              </el-table-column>
              <el-table-column prop="title" label="标题" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="send_time" label="发送时间" width="200">
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" size="small">
                    {{ scope.row.id }}查看
                  </el-button>
                  <el-button type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                hide-on-single-page
                @size-change="SizeChange"
                @current-change="CurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import talentInfo from "@/view/talentPage/talentInfo.vue";
export default {
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      activeName: "first",
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      unreadTable: [],
      readTable: [],
      lockState: "1",
      readnum: 0,
      unreadnum: 0,
    };
  },
  components: {
    talentInfo,
  },
  methods: {
    SizeChange(data) {
      this.pagesize = data;
      this.currentPage = 1;
      if (this.lockState === 1) {
        this.unreadPost();
      }
      if (this.lockState === 2) {
        this.readPost();
      }
    },
    CurrentChange(data) {
      this.currentPage = data;
      if (this.lockState === 1) {
        this.unreadPost();
      }
      if (this.lockState === 2) {
        this.readPost();
      }
    },
    changeMsgState(tab) {
      if (tab.index == 0) {
        this.lockState = 1;
        this.unreadPost();
      }
      if (tab.index == 1) {
        this.lockState = 2;
        this.readPost();
      }
    },
    unreadPost() {
      let info = {
        page: this.currentPage,
        limit: this.pagesize,
        type: 1,
      };
      this.$http.post("index/index/getsms", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.unreadTable = res.data.resultData.list;
        }
      });
    },
    readPost() {
      let info = {
        page: this.currentPage,
        limit: this.pagesize,
        type: 2,
      };
      this.$http.post("index/index/getsms", info).then((res) => {
        if (res.data.resultCode === 1) {
          this.unreadTable = res.data.resultData.list;
        }
      });
    },
    // 统计已读未读消息数量
    countNum() {
      this.$http.post("index/index/countsms").then((res) => {
        if (res.data.resultCode === 1) {
          this.readnum = res.data.resultData.read;
          this.unreadnum = res.data.resultData.unread;
        }
      });
    },
  },
  mounted() {
    this.unreadPost();
    this.countNum();
  },
};
</script>
<style scope>
.talentIndex {
  padding-top: 24px;
}
.tab-readNum {
  position: relative;
}
.readnum {
  position: absolute;
  top: 40px;
  left: 70px;
  z-index: 3;
}
.unreadnum {
  position: absolute;
  top: 40px;
  left: 166px;
  z-index: 3;
}
.top-10 {
  padding-top: 10px;
}
</style>
